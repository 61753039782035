<template>
  <section>
    <a-row type="flex" justify="center" align="middle" class="login-width">
      <a-col :xs="{ span: 20 }" :md="{ span: 14 }" :lg="{ span: 8 }">
        <a-form
          id="components-form-demo-normal-login"
          class="login-form"
          @submit="handleSubmit"
        >
          <a-form-item>
            <a-input placeholder="Username" v-model="login.username">
              <a-icon
                slot="prefix"
                type="user"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              type="password"
              placeholder="Password"
              v-model="login.password"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-item>
          <a-button type="primary" html-type="submit" class="login-form-button">
            Kirish
          </a-button>
        </a-form>
      </a-col>
    </a-row>
    <modal v-if="inValid" title="Xato" @close="closeModal">
      <p class="text-white bg-danger p-3 mt-3">
        Iltimos userName va parolli kiriting
      </p>
    </modal>
  </section>
</template>

<script>
import request from '../../api/fetchConfig';
export default {
  data() {
    return {
      inValid: false,
      login: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    closeModal(bool) {
      this.inValid = bool;
    },
    handleSubmit(e) {
      e.preventDefault();
      for (let i in this.login) {
        if (this.login[i] === '') {
          this.inValid = true;
          return;
        }
      }
      request('token/', 'POST', this.login)
        .then((res) => {
          this.$notification['success']({
            message: 'Success',
            description: "So'rovingiz muffaqiyatli amalga oshdi",
          });
          sessionStorage.setItem('token', res.access);
          this.$router.push({ name: 'Categoriya' });
        })
        .catch((error) => {
          this.$notification['error']({
            message: 'Xatolik',
            description: error.message,
          });
        });
    },
  },
};
</script>

<style>
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.login-width {
  height: 100vh;
}
</style>
