<template>
  <section>
    <login-form></login-form>
  </section>
</template>

<script>
import LoginForm from '../src/components/Login/LoginForm.vue';
export default {
  components: { LoginForm },
};
</script>
